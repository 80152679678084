import React from "react";
import "./HeaderTop.css";

import img18 from "../Image/Frame.png";

import logo from "../Image/logo_f.svg";

export default function Header() {
  return (
    <>
      <div className="header-top">
        <div className="header-img-wrapper">
          <img src={img18} alt="" />
          {/* <img src={img19} alt="" className="ss-header-img2" />
          <img src={img16} alt="" className="ss-header-img3" />
          <img src={img23} alt="" className="ss-header-img4" />
          <img src={img14} alt="" className="ss-header-img5" />
          <img src={img17} alt="" className="ss-header-img6" />
          <img src={img15} alt="" className="ss-header-img7" />
          <img src={img21} alt="" className="ss-header-img8" />
          <img src={img22} alt="" className="ss-header-img9" /> */}
        </div>
        <div className="logo-heading">
          <div style={{display:"flex"}}>
            <div className="logo-img">
              <img src={logo} alt="logo" className="th-header-logo" />
            </div>
            <div className="text">
              <p className="para1">National Social Summit</p>
              <p className="para2">10th - 11th Feb 2024</p>
            </div>
          </div>
          <div id="para3">Educate Empower Sustain</div>

        </div>
      </div>
    </>
  );
}

export const AuthActionType = {
    SIGNUP_SUCCESS : "SIGNUP_SUCCESS",
    SIGNUP_FAIL : "SIGNUP_FAIL" ,
    LOGIN_SUCCESS : "LOGIN_SUCCESS",
    LOGIN_FAIL : "LOGIN_FAIL",
    LOGOUT_SUCCESS : "LOGOUT_SUCCESS",
    LOGOUT_FAIL : "LOGOUT_FAIL",
    PROFILE_COMPLETION_SUCCESS : "PROFILE_COMPLETION_SUCCESS",
    PROFILE_COMPLETION_FAIL : "PROFILE_COMPLETION_FAIL"
}

export const AdminActionType = {
    UPLOAD_TASK_SUCCESS : "UPLOAD_TASK_SUCCESS",
    UPLOAD_TASK_FAIL : "UPLOAD_TASK_FAIL" 
}

export const GetTasksActionType = {
    GET_TASKS_SUCCESS : "GET_TASKS_SUCCESS",
    GET_TASKS_FAIL : "GET_TASKS_FAIL"
}
